import { App, Button, Form, Input, Modal, Select, Switch } from "antd";
import { fetcher, processReq } from "../../helpers/processRequest";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { DELETE, GET, POST, PUT } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import DataTable from "../../components/DataTable";
import { courseStatus, gender, messages, userRoles } from "../../utils/configConstants";
import { getLabel } from "../../utils/formatters";
import { useNavigate } from "react-router-dom";

const Staff = () => {
    const model = '/auth/staff';
    const result = useSWR({method: GET, url: API_ROUTE + model} , fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const navigate = useNavigate();

    useEffect(() => {
        resetForm();
    }, [])

    const deleteRecord = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + model + '/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }
        
        result.mutate();
    }

    const editRecord = (record) => {
        let object = {
            ...record,
        }

        setSelectedRecord(object);
        form.setFieldsValue(object);
        setIsModalOpen(true);
    }

    const moreAction = (record) => {
        navigate('manage', {state: {...record, userRole: userRoles.teacher}})
    }


    const addRecord = () => {
        form.resetFields();
        resetForm();
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (data) => {
        if (selectedRecord.id) {
            await processReq(PUT, API_ROUTE + model, {...data, id: selectedRecord.id});
            result.mutate();
            message.success('Updated!')
        } else {
            await processReq(POST, API_ROUTE + model, data);
            result.mutate();
            message.success('Saved!')
        }
        
        handleCancel();
    }

    const resetForm = () => {
        setSelectedRecord({})
    }

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-2xl">Staff Management</div>
            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Record</Button></div>
            <div>
                <DataTable 
                    columns={[
                    "id",
                    "firstName",
                    "lastName",
                    "address",
                    "genderLabel",
                    "nic",
                    "mobile",
                    "whatsAppMobile"
                    ]}
                    data={result.data}
                    isLoading={result.isLoading}
                    deleteAction={deleteRecord}
                    editAction={editRecord}
                    moreAction={moreAction}
                />
            </div>

            <Modal maskClosable={false} title="Add New Record" open={isModalOpen} onCancel={handleCancel} okButtonProps={{hidden: true}} cancelButtonProps={{hidden: true}}>
                <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                    <Form.Item label='First Name' name={'firstName'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input placeholder="Enter text"/>
                    </Form.Item>

                    <Form.Item label='Last Name' name={'lastName'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input placeholder="Enter text"/>
                    </Form.Item>

                    <Form.Item label='Address' name={'address'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input placeholder="Enter text"/>
                    </Form.Item>

                    <Form.Item label='Gender' name={'gender'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Select
                            options={gender.formData}
                        />
                    </Form.Item>

                    <Form.Item label='National Identity Card No.' name={'nic'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input placeholder="Enter text"/>
                    </Form.Item>

                    <Form.Item label='Mobile Phone No' name={'mobile'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input placeholder="Enter text"/>
                    </Form.Item>

                    <Form.Item label='WhatsApp Mobile Number' name={'whatsAppMobile'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input placeholder="Enter text"/>
                    </Form.Item>

                    <Form.Item label='Rate Per Hour' name={'perHourRate'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input type="number" placeholder="Enter text"/>
                    </Form.Item>
                        
                    <Form.Item label='Rate Per Day' name={'perDayRate'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input type="number" placeholder="Enter text"/>
                    </Form.Item>
                    
                    <Form.Item label='Rate Per Month' name={'perMonthRate'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input type="number" placeholder="Enter text"/>
                    </Form.Item>

                    <Form.Item className="text-right">
                        <Button htmlType="submit">Save Record</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Staff;