import { useEffect, useState } from "react";
import { API_ROUTE } from "../utils/commonConstants";
import { GET, POST } from "../utils/requestActionConstants";
import { processReq } from "../helpers/processRequest";
import { createContext } from "react";
import { userRoles } from "../utils/configConstants";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext(null);

const AuthContextProvider = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [user, setUser] = useState({});

    useEffect(() => {
        checkAuth();
    }, [])

    const checkAuth = async () => {
        let token = sessionStorage.getItem('token');

        if ((token && token.length > 0)) {
            setIsLoggedIn(true);
            setIsAuthLoading(true);
            verifyUser();
        } else {
            setIsLoggedIn(false);
        }

        setIsAuthLoading(false);
    }

    const logOut = async () => {
        setIsLoggedIn(false);
        sessionStorage.removeItem('token');
        setUser(null);

        window.location.replace('/')
    }

    const login = async (email, password) => {
        const response = await processReq(POST, API_ROUTE + '/login', {email, password});

        if (response.error) {
            // console.log('Processing failed!', response.message);
            sessionStorage.clear();

            return response.message;
        } else {
            // console.log('printing response', response);
            setUser(response);
            sessionStorage.setItem('token', response.token);
            checkAuth();
        }

    }

    const verifyUser = async () => {
        const response = await processReq(POST, API_ROUTE + '/auth/user/verify');

        if (response.error) {
            logOut();
        } else {

            let user = response;

            setUser(user);
        }
    }

    return (
        <AuthContext.Provider value={{logOut, isLoggedIn, login, user, verifyUser: verifyUser, isAuthLoading}}>{children}</AuthContext.Provider>
    );
}

export default AuthContextProvider;
