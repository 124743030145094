export const isEmptyString = (data) => {
    return (
        data === '' ||
        data === undefined ||
        data === null
    );
}

export const isObjectByKeysDuplicated = (arr, obj, keys) => {
    return arr.some(item =>
        keys.every(key => item[key] === obj[key])
    );
}

export const isObjectDuplicated = (arr, obj) => {
    return arr.some(item =>
        Object.keys(obj).every(key => obj[key] === item[key]) &&
        Object.keys(item).length === Object.keys(obj).length
    );
}

export const hasDuplicates = (arr, key) => {
    for (const a of arr) {
        let count = 0;

        arr.forEach(x => {
            if (x[key] === a[key]) {
                count += 1
            }
        })

        if (count > 1) {
            return true
        }
    }

    return false;
}