export const lecturers = [
    {
        id: 1,
        name: 'Thanish',
        value: 'Thanish',
        label: 'Thanish',
    },
    {
        id: 2,
        name: 'Riswan',
        value: 'Riswan',
        label: 'Riswan'
    },
    {
        id: 3,
        name: 'Rismi',
        value: 'Rismi',
        label: 'Rismi'
    }
]

export const subjectsData = [
    {
        id: 1,
        name: 'Chemistry',
        value: 'Chemistry',
        label: 'Chemistry',
        lecturers: [lecturers[0], lecturers[1]]
    },
    {
        id: 2,
        name: 'Physics',
        value: 'Physics',
        label: 'Physics',
        lecturers: [lecturers[1], lecturers[2]]
    }
]

export const courses = [
    {
        id: 1,
        name: 'Science',
        year: '2023',
        value: 'Science Stream 2023',
        label: 'Science Stream 2023',
    },
    {
        id: 2,
        name: 'Commerce',
        year: '2023',
        value: 'Commerce Stream 2023',
        label: 'Commerce Stream 2023',
    },
    {
        id: 3,
        name: 'Science',
        year: '2024',
        value: 'Science Stream 2024',
        label: 'Science Stream 2024',
    }
]

export const timetables = [
    {
        "id": 1,
        "startDate": "2023-12-01T17:01:31.658Z",
        "endDate": "2023-12-02T17:01:31.658Z",
        "description": "Science Stream 2023",
        "timeTableDays": [
            {
                "id": 0,
                "date": "2023-12-01T17:01:31.658Z",
                "title": "2023-12-01 Friday",
                "timeTableDaySlots": [
                    {
                        "from": "2023-12-01T00:00:00.565Z",
                        "to": "2023-12-01T02:00:00.565Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 2,
                            "name": "Riswan",
                            "value": "Riswan",
                            "label": "Riswan"
                        }
                    }
                ]
            },
            {
                "id": 1,
                "date": "2023-12-02T17:01:31.658Z",
                "title": "2023-12-02 Saturday",
                "timeTableDaySlots": [
                    null,
                    {
                        "from": "2023-12-01T02:00:02.418Z",
                        "to": "2023-12-01T02:30:02.418Z",
                        "subject": {
                            "id": 2,
                            "name": "Physics",
                            "value": "Physics",
                            "label": "Physics",
                            "lecturers": [
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                },
                                {
                                    "id": 3,
                                    "name": "Rismi",
                                    "value": "Rismi",
                                    "label": "Rismi"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 3,
                            "name": "Rismi",
                            "value": "Rismi",
                            "label": "Rismi"
                        }
                    },
                    {
                        "from": "2023-12-01T02:30:02.600Z",
                        "to": "2023-12-01T04:30:02.600Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 1,
                            "name": "Thanish",
                            "value": "Thanish",
                            "label": "Thanish"
                        }
                    }
                ]
            }
        ],
        "course": {
            "id": 1,
            "name": "Science",
            "year": "2023",
            "value": "Science Stream 2023",
            "label": "Science Stream 2023"
        }
    },
    {
        "id": 2,
        "startDate": "2023-12-01T17:47:11.894Z",
        "endDate": "2023-12-02T17:47:11.894Z",
        "description": "Commerce Stream 2023",
        "timeTableDays": [
            {
                "id": 0,
                "date": "2023-12-01T17:47:11.894Z",
                "title": "2023-12-01 Friday",
                "timeTableDaySlots": [
                    null,
                    null,
                    null,
                    {
                        "from": "2023-12-01T04:45:36.272Z",
                        "to": "2023-12-01T06:45:36.272Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 2,
                            "name": "Riswan",
                            "value": "Riswan",
                            "label": "Riswan"
                        }
                    },
                    {
                        "from": "2023-12-01T07:15:36.957Z",
                        "to": "2023-12-01T08:30:36.957Z",
                        "subject": {
                            "id": 2,
                            "name": "Physics",
                            "value": "Physics",
                            "label": "Physics",
                            "lecturers": [
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                },
                                {
                                    "id": 3,
                                    "name": "Rismi",
                                    "value": "Rismi",
                                    "label": "Rismi"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 3,
                            "name": "Rismi",
                            "value": "Rismi",
                            "label": "Rismi"
                        }
                    }
                ]
            },
            {
                "id": 1,
                "date": "2023-12-02T17:47:11.894Z",
                "title": "2023-12-02 Saturday",
                "timeTableDaySlots": [
                    {
                        "from": "2023-12-01T00:00:25.066Z",
                        "to": "2023-12-01T02:00:25.066Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 1,
                            "name": "Thanish",
                            "value": "Thanish",
                            "label": "Thanish"
                        }
                    }
                ]
            },
            {
                "id": 0,
                "date": "2023-12-01T17:47:11.894Z",
                "title": "2023-12-01 Friday",
                "timeTableDaySlots": [
                    null,
                    null,
                    null,
                    {
                        "from": "2023-12-01T04:45:36.272Z",
                        "to": "2023-12-01T06:45:36.272Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 2,
                            "name": "Riswan",
                            "value": "Riswan",
                            "label": "Riswan"
                        }
                    },
                    {
                        "from": "2023-12-01T07:15:36.957Z",
                        "to": "2023-12-01T08:30:36.957Z",
                        "subject": {
                            "id": 2,
                            "name": "Physics",
                            "value": "Physics",
                            "label": "Physics",
                            "lecturers": [
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                },
                                {
                                    "id": 3,
                                    "name": "Rismi",
                                    "value": "Rismi",
                                    "label": "Rismi"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 3,
                            "name": "Rismi",
                            "value": "Rismi",
                            "label": "Rismi"
                        }
                    }
                ]
            },
            {
                "id": 1,
                "date": "2023-12-02T17:47:11.894Z",
                "title": "2023-12-02 Saturday",
                "timeTableDaySlots": [
                    {
                        "from": "2023-12-01T00:00:25.066Z",
                        "to": "2023-12-01T02:00:25.066Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 1,
                            "name": "Thanish",
                            "value": "Thanish",
                            "label": "Thanish"
                        }
                    }
                ]
            }
        ],
        "course": {
            "id": 2,
            "name": "Commerce",
            "year": "2023",
            "value": "Commerce Stream 2023",
            "label": "Commerce Stream 2023"
        }
    },
]