import { POST } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import { processReq } from "../../helpers/processRequest";
import { Button, Form, Upload } from "antd";
import {useContext, useEffect, useState} from "react";
import papaparse from "papaparse";
import DataTable from "../../components/DataTable";
import { SocketContext } from "../../App";
import SMSSender from "./SMSSender";

const SMS = () => {
    const [form] = Form.useForm();
    const [dataArray, setDataArray] = useState([]);
    const [dataArrayHeaders, setDataArrayHeaders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const socket = useContext(SocketContext);
    const [isSendingDisabled, setIsSendingDisabled] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        if (dataArray.length > 0) {
            socket.on('sms_send', (response) => {
                setMessage("Sending Messages " + response.count + " of " + dataArray.length);

                if (dataArray.length === response.count) {
                    setMessage("Processing Success!");
                    setIsSendingDisabled(true);
                }
            })
        }
    }, [dataArray.length])

    const uploadCSV = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
    
        parseCsv(file);        
        onSuccess('Uploaded');
    }

    const deleteCSV = (data) => {
        setDataArray([{}]);
        setMessage('');
    }
    
    const parseCsv = (file) => {
        if (file) {
            papaparse.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setDataArray(results.data);
                setIsSendingDisabled(false);
                // setDataArrayHeaders(Object.keys(results.data[0]));
            }
            });
        } else {
            alert("Please choose a csv file");
        }
    };

    const handleOnSubmit = async () => {
        let isSubmitEnabled = true;

        if (isSubmitEnabled) {
            setIsLoading(true);
            // let filteredData = dataArray.filter(dt => (dt.mobile.length === 11));
            let filteredData = dataArray;
            
            try {
                let messages = filteredData;
                let chunkSize = 20;
                let chunks = [];
                let sentCount = 0;
    
                for (let i = 0; i < messages.length; i += chunkSize) {
                    chunks.push(messages.slice(i, i + chunkSize));
                }

                setMessage('Loading...');

                for (let chunk of chunks) {
                    let reqObj = {
                        fileData: chunk,
                        count: sentCount,
                        // socketId: socketId
                    }

                    await processReq(POST, API_ROUTE + '/auth/sms/send', reqObj);
                    sentCount = sentCount + chunk.length;
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            alert('submit disabled to avoid development level sms sending mistakes')
        }
        
      };


    const handleSingleSMS = () => {
        setIsModalOpen((old)=>!old);
    }

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-2xl">SMS Manager</div>
            <Form form={form} className="pt-5" layout="vertical">
                <Form.Item label="Upload" valuePropName="fileList">
                    <Upload listType="picture-card" maxCount={1} onRemove={deleteCSV} customRequest={uploadCSV} accept=".csv">
                        <div className="text-4xl">+</div>
                    </Upload>
                </Form.Item>
            </Form>

            <div className="py-5">{message}</div>

            <Button onClick={handleOnSubmit} disabled={isSendingDisabled}>Send Bulk SMS</Button>
            <Button onClick={handleSingleSMS}>Send One Time SMS</Button>
            <div className={'pt-5'}>
                <DataTable
                    columns={[
                        "mobile",
                        "message",
                    ]}
                    data={dataArray}
                />
            </div>
            <SMSSender isOpen={isModalOpen} />
        </div>
    );
}

export default SMS;