import useSWR, { mutate } from "swr";
import { DELETE, GET, POST, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import EditInput from "../../../components/EditInput";
import { App, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useState } from "react";

const ContactDetails = ({formData}) => {
    const { message, modal } = App.useApp();
    const contactDetailsRequest = useSWR({method: GET, url: API_ROUTE + '/auth/contactInfo/student/'+formData.studentId} , fetcher);
    const mobileNumbers = contactDetailsRequest.data;

    let onUpdate = (value, field, dataObject) => {
        return new Promise(async (resolve, reject) => {
            let error = false;
            let reqObject = dataObject;

            reqObject[field] = value;

            let result = await processReq(PUT, API_ROUTE + '/auth/contactInfo', reqObject);

            error = !(result && result.length > 0);

            if (error) {
                reject('Failed')
            } else {
                resolve('success');
                contactDetailsRequest.mutate();
            }
        })
    }

    let addMobile = async () => {
        if (mobileNumbers.length < 3) {
            message.loading('Loading...', 0);

            let reqObject = {mobNumber: 'Add Mobile Number', description: 'Add Description', studentId: formData.studentId}
            let result = await processReq(POST, API_ROUTE + '/auth/contactInfo', reqObject);

            message.destroy();
            message.success('Created!');
            contactDetailsRequest.mutate();
        }
    }

    let deleteMobile = async (mobile) => {
        modal.confirm({
            title: 'Do you Want to delete this?',
            icon: <ExclamationCircleFilled />,
            content: `You are going to delete ${mobile.mobNumber}`,
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                className: 'bg-red-800'
            },
            async onOk() {
                message.loading('Deleting', 0)

                const response = await processReq(DELETE, API_ROUTE + '/auth/contactInfo/del/'+mobile.id);

                if (!response.error) {
                    message.destroy();
                    message.success('Deleted');
                    contactDetailsRequest.mutate();
                } else {
                    message.success('Deleting Failed');
                }
            },
            onCancel() {
            //    console.log('not deleted')
            },
          });
    }

    return (
        <div>
            {
                contactDetailsRequest.isLoading ? 'Loading' : 

                <div className="p-5 bg-white/60 rounded-xl fade shadow-lg">
                    <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                        <div className="text-xl pb-3">Contact Details</div>
                        {
                            mobileNumbers.map((mobile, index) => {
                                return (
                                        <div className="flex flex-col justify-between md:flex-row p-2 rounded-lg mb-4 border" key={index}>
                                            <div className="w-2/4">
                                                <span className="font-bold">{index + 1}) Mobile: </span>
                                                <div className="pl-2 inline-block md:block">
                                                    <EditInput 
                                                        masterData={mobile.mobNumber} 
                                                        onSuccess={onUpdate} 
                                                        field={'mobNumber'}
                                                        object={mobile}
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-2/4 pl-4">
                                                <span className="font-bold">Description: </span>
                                                <div className="pl-2 inline-block md:block">
                                                <EditInput 
                                                    masterData={mobile.description} 
                                                    onSuccess={onUpdate} 
                                                    field={'description'}
                                                    object={mobile}
                                                />
                                                </div>
                                            </div>

                                            <Button onClick={() => deleteMobile(mobile)}>Delete</Button>
                                        </div>
                                )
                            })
                        }
                        <Button disabled={mobileNumbers.length > 2} onClick={addMobile}>Add</Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default ContactDetails;