import { App, Button, DatePicker, Form, Input, Modal, Select, notification } from "antd";
import DataTable from "../../components/DataTable";
import { fetcher, processReq } from "../../helpers/processRequest";
import { API_ROUTE } from "../../utils/commonConstants";
import { DELETE, GET, POST, PUT } from "../../utils/requestActionConstants";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { enrollmentStatus, messages } from "../../utils/configConstants";

const Enrollments = () => {
    const model = '/auth/enrollment';
    const enrollments = useSWR({method: GET, url: API_ROUTE + model} , fetcher);
    const students = useSWR({method: GET, url: API_ROUTE + '/auth/student'} , fetcher);
    const courses = useSWR({method: GET, url: API_ROUTE + '/auth/course'} , fetcher);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const { message } = App.useApp();

    const editData = (data) => {
        navigate('manage', {state: data})
    }

    const enrollStudent = () => {
        form.resetFields();
        resetForm();
        setIsModalOpen(true);
    }

    const onFinish = async (data) => {
        const course = await processReq(GET, API_ROUTE + "/auth/course/dist/" + data.courseId);
        await processReq(POST, API_ROUTE + model, {
            ...data,
            monthlyFee: course.monthlyFee,
            admissionFee: course.admissionFee,
            enrollmentStatus: enrollmentStatus.pending
        });

        enrollments.mutate();
        message.success('Saved!')
        
        handleCancel();
    }

    const resetForm = () => {
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const deleteRecord = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + model + '/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }
        
        enrollments.mutate();
    }


    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-2xl pb-10">Student Course Enrollment Management</div>
            <div className="text-3xl pb-5 text-right flex justify-end"><Button onClick={enrollStudent}>Enroll Student</Button></div>
            <div>
                <DataTable 
                    columns={[
                    "fullName",
                    "courseName",
                    "status",
                    ]}
                    data={enrollments.data}
                    isLoading={enrollments.isLoading}
                    deleteAction={deleteRecord}
                    moreAction={editData}
                />
            </div>

            <Modal maskClosable={false} title="Add New Record" open={isModalOpen} onCancel={handleCancel} okButtonProps={{hidden: true}} cancelButtonProps={{hidden: true}}>
                <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                    <Form.Item label='Student' name={'studentId'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Select
                            options={students.data}
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item label='Course' name={'courseId'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Select
                            options={courses.data}
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item className="text-right">
                        <Button htmlType="submit">Enroll Now</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Enrollments;