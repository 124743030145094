import useSWR from "swr";
import Button from "../../components/Button";
import { BarChart } from "../../components/chart/BarChart";
import { LineChart } from "../../components/chart/LineChart";
import { PieChart } from "../../components/chart/PieChart";
import { fetcher } from "../../helpers/processRequest";
import { API_ROUTE } from "../../utils/commonConstants";
import { GET } from "../../utils/requestActionConstants";
import { useNavigate } from "react-router-dom";
import {enrollmentStatus, userRoles} from "../../utils/configConstants";
import {useContext, useEffect, useState} from "react";
import Marquee from "react-fast-marquee";
import {AuthContext} from "../../context/AuthContextProvider";

const AdminDashboard = () => {
    const navigate = useNavigate();
    const studentResponse = useSWR({method: GET, url: API_ROUTE + '/auth/student'} , fetcher);
    const staffResponse = useSWR({method: GET, url: API_ROUTE + '/auth/course'} , fetcher);
    const courseResponse = useSWR({method: GET, url: API_ROUTE + '/auth/staff'} , fetcher);
    const postsResponse = useSWR({method: GET, url: API_ROUTE + '/posts'} , fetcher);
    const lecturerResponse = useSWR({method: GET, url: API_ROUTE + '/auth/lecturer'} , fetcher);
    const enrollment = useSWR({method: GET, url: API_ROUTE + '/auth/enrollment'} , fetcher);
    const passouts = enrollment.data?.filter(en => {
        // console.log(en)
        return en?.enrollmentStatus?.toString() === enrollmentStatus.completed.toString()
    })
    const [noticeString, setNoticeString] = useState('');
    const result = useSWR({ method: GET, url: API_ROUTE + '/auth/notice' }, fetcher);

    useEffect(() => {
        handleNoticeUpdate();
    }, [result.data])

    const handleNoticeUpdate = () => {
        const noticesToShow = [];

        result.data?.filter((notice) => {
            if (notice.isVisible) {
                noticesToShow.push(notice.content);
            }
        })

        setNoticeString(noticesToShow.reverse().join(' - '));
    }

    return (
        <div className="pop-left fade px-5 md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-3xl pb-5">Dashboard</div>
            <div className="gradient-bg-1 rounded-2xl py-2 my-5 text-lg overflow-hidden px-5"><Marquee speed={100} style={{overflow: 'hidden'}}>{noticeString}</Marquee></div>
            <div className="grid gap-10 md:grid-cols-3 pb-10">
                <div className="p-4 py-8 rounded-xl shadow-xl text-center bg-green-400 gradient-bg-3">
                    <div className="text-xl">Students</div>
                    <div className="text-3xl">{studentResponse.data?.length}</div>
                    <div className="text-xl pt-3"><Button onClick={() => navigate('/students')}>Manage</Button></div>
                </div>
                <div className="p-4 py-8 rounded-xl shadow-xl text-center bg-blue-400 gradient-bg-1">
                    <div className="text-xl">Lecturers</div>
                    <div className="text-3xl">{lecturerResponse.data?.length}</div>
                    <div className="text-xl pt-3"><Button onClick={() => navigate('/lecturers')} style="danger">Manage</Button></div>
                </div>
                <div className="p-4 py-8 rounded-xl shadow-xl text-center bg-violet-400 gradient-bg-3">
                    <div className="text-xl">Pass outs</div>
                    <div className="text-3xl">{passouts?.length}</div>
                    <div className="text-xl pt-3"><Button onClick={() => navigate('/enrollment')}>Manage</Button></div>
                </div>
            </div>
            <div className="grid gap-10 md:grid-cols-3 pb-10">
                <div className="p-4 py-8 rounded-xl shadow-xl text-center bg-blue-400 gradient-bg-1">
                    <div className="text-xl">Course</div>
                    <div className="text-3xl">{courseResponse.data?.length}</div>
                    <div className="text-xl pt-3"><Button onClick={() => navigate('/courses')} style="danger">Manage</Button></div>
                </div>
                <div className="p-4 py-8 rounded-xl shadow-xl text-center bg-green-400 gradient-bg-3">
                    <div className="text-xl">Staff</div>
                    <div className="text-3xl">{staffResponse.data?.length}</div>
                    <div className="text-xl pt-3"><Button onClick={() => navigate('/staff')}>Manage</Button></div>
                </div>
                <div className="p-4 py-8 rounded-xl shadow-xl text-center bg-blue-400 gradient-bg-1">
                    <div className="text-xl">Posts</div>
                    <div className="text-3xl">{postsResponse.data?.length}</div>
                    <div className="text-xl pt-3"><Button onClick={() => navigate('/blog')} style="danger">Manage</Button></div>
                </div>
            </div>
            {/* <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3">
                <div className=""><LineChart /></div>
                <div className=""><PieChart /></div>
                <div className=""><BarChart /></div>
            </div> */}
        </div>
    );
}

export default AdminDashboard;
