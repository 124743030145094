import useSWR from "swr";
import Button from "../../components/Button";
import { BarChart } from "../../components/chart/BarChart";
import { LineChart } from "../../components/chart/LineChart";
import { PieChart } from "../../components/chart/PieChart";
import { fetcher } from "../../helpers/processRequest";
import { API_ROUTE } from "../../utils/commonConstants";
import { GET } from "../../utils/requestActionConstants";
import { useNavigate } from "react-router-dom";
import {enrollmentStatus, userRoles} from "../../utils/configConstants";
import {useContext, useEffect, useState} from "react";
import Marquee from "react-fast-marquee";
import {AuthContext} from "../../context/AuthContextProvider";
import AdminDashboard from "./AdminDashboard";
import StudentDashboard from "./StudentDashboard";

const Dashboard = () => {
    const {user} = useContext(AuthContext);

    if (user.userRole === userRoles.student) {
        return <StudentDashboard />
    } else if (user.userRole === userRoles.admin) {
        return <AdminDashboard />
    } else {
        return <></>
    }
}

export default Dashboard;
