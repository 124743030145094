import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import ManageStudent from "./tabItems/ManageStudent";
import ContactDetails from "./tabItems/ContactDetails";
import UserProfileView from "../../components/UserProfileView";

const ManageStudentTab = () => {
    const location = useLocation();
    const enrollment = location.state;
    enrollment.studentId = enrollment.id;
    const navigate = useNavigate();

    const tabs = [
        {
          key: '2',
          label: 'Student',
          children: <ManageStudent formData={enrollment} />,
        },
        {
          key: '3',
          label: 'Student Contact Details',
          children: <ContactDetails formData={enrollment}/>,
        },
        // {
      //   key: '4',
      //   label: 'Parents',
      //   children: 'Content of Tab Pane 3',
      // },
        {
            key: '5',
            label: 'User Account',
            children: <UserProfileView formData={enrollment}/>,
          },
        
    ];

    const onChange = (key) => {
        // console.log(key);
    };

    return (
        <div>
            <div className="">
                <div onClick={() => navigate('/students')} className="p-3 rounded-lg bg-cyan-50 w-10 h-10 mb-2 cursor-pointer hover:scale-110 shadow duration-200 ease-in-out"><div className="icon-arrow-left"></div></div>
                <Tabs defaultActiveKey="1" items={tabs} onChange={onChange} tabBarStyle={{fontWeight: 'bold', backgroundColor: '#e4f6ff', borderRadius: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10, boxShadow: 'rgb(218 229 235) 0px 3px 9px 0px'}} />
            </div>
        </div>
    )
}

export default ManageStudentTab;