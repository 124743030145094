import useSWR, { mutate } from "swr";
import { DELETE, GET, POST, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import EditInput from "../../../components/EditInput";
import { App, Button, Form, Input, Modal, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { commonBoolean, messages } from "../../../utils/configConstants";
import { useContext, useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { AuthContext } from "../../../context/AuthContextProvider";
import Attendance from "../../../components/Attendance";

const SessionRecord = () => {
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const { formData } = location.state;
    const model = '/auth/timetableDaySlot';
    const timetableDaySlotResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/timetableDaySlot/dist/' + formData.id }, fetcher);
    const lecturerResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/lecturer/dist/' + formData.lecturerId }, fetcher);
    const lecturer = lecturerResponse.data;
    const result = useSWR({ method: GET, url: API_ROUTE + model }, fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const navigate = useNavigate();

    const onFinishContentCovered = async (data) => {
        await processReq(POST, API_ROUTE + '/auth/lecturerRecord', {
            ...data,
            lecturerId: lecturer.id,
            userId: user.id,
            timeTableDaySlotId: timetableDaySlotResponse.data.id
        });

        timetableDaySlotResponse.mutate();
        message.success('Saved!')
    }

    const generateAttendance = async () => {
        await processReq(POST, API_ROUTE + '/auth/studentAttendance/generate', {
            userId: user.id,
            timeTableDaySlotId: timetableDaySlotResponse.data.id,
            courseId: timetableDaySlotResponse.data.timeTableDay?.timeTable?.courseId,
        });

        timetableDaySlotResponse.mutate();
        message.success('Saved!')
    }

    return (
        <div>
            {

                timetableDaySlotResponse.isLoading ? 'Loading' :

                <div className="p-5 bg-white/60 rounded-xl fade shadow-lg">
                        {/*{console.log(timetableDaySlotResponse.data)}*/}
                        <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                            <Button onClick={() => navigate(-1)}>Go Back</Button>
                            <div className="text-2xl font-bold py-3">Session Summary</div>
                            <div className="">Lecturer: {timetableDaySlotResponse.data.lecturer?.firstName}</div>
                            <div className="">Course: {timetableDaySlotResponse.data.courseName}</div>
                            <div className="">Time: {dayjs(timetableDaySlotResponse.data.from).format('hh:mm A')} - {dayjs(timetableDaySlotResponse.data.to).format('hh:mm A')}</div>

                            {timetableDaySlotResponse.data.lecturerRecord ?
                                <div>
                                    <div className="">Content Covered: {timetableDaySlotResponse.data.lecturerRecord?.contentCovered}</div>
                                    <div className="">Marked By: {timetableDaySlotResponse.data.lecturerRecord?.user?.userName}</div>
                                </div>
                                :
                                <div>
                                    <Form className="pt-5" onFinish={onFinishContentCovered} layout="vertical">
                                        <Form.Item label='Content Covered' name={'contentCovered'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                                            <TextArea />
                                        </Form.Item>

                                        <Form.Item className="text-right">
                                            <Button htmlType="submit">Mark Record</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            }
                            {
                                timetableDaySlotResponse.data.studentAttendances.length > 0 ?
                                    <Attendance timetableDaySlotId={timetableDaySlotResponse.data.id}/>
                                    :
                                    <div className="text-3xl pb-5"><Button onClick={generateAttendance}>Generate Attendance</Button></div>
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

export default SessionRecord;
