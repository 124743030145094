import { DeleteOutlined, DoubleRightOutlined, DownCircleFilled, DownloadOutlined, EditFilled, ExpandAltOutlined, FileExcelFilled, FilePdfFilled, MoonFilled, MoreOutlined, PrinterFilled, SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Popconfirm, Space, Table } from 'antd';
import CsvLink from 'react-csv-export';
import { usePDF } from 'react-to-pdf';

const DataTable = ({ columns, data, isLoading, deleteAction, editAction, moreAction, printAction }) => {
    const { toPDF, targetRef } = usePDF({ filename: 'clc_data.pdf', page: {orientation: 'landscape'} });
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        className='bg-blue-700'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const [formattedColumns, setFormattedColumns] = useState([]);
    const [formattedData, setFormattedData] = useState([]);

    useEffect(() => {
        formatColumns();
        formatData();
    }, [data, columns])

    const formatColumns = () => {
        const formattedCols = columns.map(rawColumn => {
            let columnsArray = rawColumn.split('.');
            let column = columnsArray.length > 1 ? columnsArray[1] : columnsArray[0];

            let camelCase = column.replace(/([A-Z])/g, " $1");

            let formatted = {
                title: camelCase.charAt(0).toUpperCase() + camelCase.slice(1),
                dataIndex: column.toString(),
                key: column.toString(),
                ...getColumnSearchProps(column.toString()),
                sorter: (a, b) => a[column.toString()] > b[column.toString()],
                defaultSortOrder: 'descend',
                sortDirections: ['descend'],
            };

            return formatted;
        });

        if (editAction || deleteAction) {
            formattedCols.push({
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        {deleteAction ?
                            <Popconfirm
                                title="Delete the task"
                                description="Are you sure to delete this record?"
                                onConfirm={() => deleteData(record)}
                                okButtonProps={{ danger: true }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                            : ''}

                        {editAction ?
                            <button onClick={() => editData(record)}><EditFilled /></button>
                            : ''}
                        {moreAction ?
                            <button onClick={() => moreData(record)}><DoubleRightOutlined /></button>
                            : ''}
                        {printAction ?
                            <button onClick={() => printData(record)}><PrinterFilled /></button>
                            : ''}
                    </Space>
                )
            });
        }



        setFormattedColumns(formattedCols);
    }

    const formatData = () => {
        if (data && data.length > 0) {
            let formattedRows = data.map((item, index) => {
                return ({ ...item, key: (index + 1).toString() })
            });

            setFormattedData(formattedRows.reverse());
        }
    }

    const deleteData = (record) => {
        deleteAction(record);
    }

    const editData = (record) => {
        editAction(record);
    }

    const moreData = (record) => {
        moreAction(record);
    }

    const printData = (record) => {
        printAction(record);
    }

    return (
        <div className='bg-white rounded-xl shadow max-w-full'>
            <div className='flex justify-end pb-4 gap-2'>
                <CsvLink data={formattedData} fileName="clc_data" withTimeStamp>
                    <div className='text-right text-lg'><FileExcelFilled /></div>
                </CsvLink>
                <FilePdfFilled className='text-lg' onClick={() => toPDF()} />

            </div>
            <div >
                <Table className='' columns={formattedColumns} dataSource={formattedData} loading={isLoading} scroll={{ y: 400 }} />
            </div>

            <div className='absolute opacity-0 -z-10' style={{width: '100vw'}}>
                <div ref={targetRef} style={{margin: 'auto', padding: 20}}>
                    <table className='border' width={'100%'} >
                        <thead>
                            <tr className='border-b'>
                                {formattedColumns.map((col, index) => {
                                    if (col.title !== 'Action') {
                                        return (
                                            <th className='border-r p-2' key={`${index}_row_head`}>{col.title}</th>
                                        )
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {formattedData.map((row, index) => {
                                return (
                                    <tr key={`${row.id}_row`} className='border-b'>
                                        {
                                            columns.map(dt => {
                                                return <td className='border-r p-2' key={`${index}-${dt}_row_cell`}>{row[dt]}</td>
                                            })
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
export default DataTable;