import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import ExamSubject from "./tabItems/ExamSubject";

const ManageExamTab = () => {
    const location = useLocation();
    const exam = location.state;
    const navigate = useNavigate();

    const tabs = [
        {
            key: '1',
            label: 'Exam Subjects',
            children: <ExamSubject formData={exam}/>,
          },
        
    ];

    const onChange = (key) => {
        // console.log(key);
    };

    return (
        <div>
            <div className="">
                <div onClick={() => navigate('/exam')} className="p-3 rounded-lg bg-cyan-50 w-10 h-10 mb-2 cursor-pointer hover:scale-110 shadow duration-200 ease-in-out"><div className="icon-arrow-left"></div></div>
                <Tabs defaultActiveKey="1" items={tabs} onChange={onChange} tabBarStyle={{fontWeight: 'bold', backgroundColor: '#e4f6ff', borderRadius: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10, boxShadow: 'rgb(218 229 235) 0px 3px 9px 0px'}} />
            </div>
        </div>
    )
}

export default ManageExamTab;