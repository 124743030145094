import { Link, useNavigate } from "react-router-dom";
import logo from '../assets/img/logo.png'
import {useContext, useEffect, useState} from "react";
import {userRoles} from "../utils/configConstants";
import {AuthContext} from "../context/AuthContextProvider";

export const navs = [
    {
        id: 1,
        label: "Dashboard",
        route: "/",
        icon: "icon-home pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.student, userRoles.editor, userRoles.teacher]
    },
    {
        id: 2,
        label: "Manage Blog",
        route: "/blog",
        icon: "icon-blog pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 3,
        label: "File Manager",
        route: "/filemanager",
        icon: "icon-tasks pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 4,
        label: "SMS",
        route: "/sms",
        icon: "icon-email-envelope pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin]
    },
    // {
    //     id: 5,
    //     label: "Dormitory",
    //     route: "/dormitory",
    //     icon: "icon-building-o pr-2 pt-1",
    //     isActive: false,
    // },
    {
        id: 7,
        label: "Students",
        route: "/students",
        icon: "icon-graduation-cap pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 8,
        label: "Subjects",
        route: "/subjects",
        icon: "icon-graduation-cap-1 pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 9,
        label: "Lecturers",
        route: "/lecturers",
        icon: "icon-person pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 10,
        label: "Courses",
        route: "/courses",
        icon: "icon-book pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 11,
        label: "Enrollments",
        route: "/enrollment",
        icon: "icon-book pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    // {
    //     id: 12,
    //     label: "Inquiries",
    //     route: "/inquiries",
    //     icon: "icon-help pr-2 pt-1",
    //     isActive: false,
    // },
    {
        id: 13,
        label: "Time Table",
        route: "/timetable",
        icon: "icon-calendar pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 6,
        label: "Exams",
        route: "/exam",
        icon: "icon-book pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 17,
        label: "Staff",
        route: "/staff",
        icon: "icon-person pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 16,
        label: "Notice Board",
        route: "/noticeboard",
        icon: "icon-speakerphone pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 14,
        label: "Notice View",
        route: "/noticeView",
        icon: "icon-speakerphone pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        id: 15,
        label: "Tasks",
        route: "/tasks",
        icon: "icon-calendar pr-2 pt-1",
        isActive: false,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    // {
    //     id: 19,
    //     label: "Reports",
    //     route: "/report",
    //     icon: "icon-blog pr-2 pt-1",
    //     isActive: false,
    // },
    // {
    //     id: 18,
    //     label: "Reports",
    //     route: "/payroll",
    //     icon: "icon-blog pr-2 pt-1",
    //     isActive: false,
    // },
];
function Sidebar(props) {
    const {isLoggedIn, user} = useContext(AuthContext);
    const [activeRoute, setActiveRoute] = useState('');
    const navigate = useNavigate();

    const switchRoute = (route) => {
        navigate(route);
        setActiveTab();
        props.setHidden(true);
    }

    const setActiveTab = () => {
        setActiveRoute(window.location.pathname.split('/')[1]);
    }

    useEffect(() => {
        setActiveTab();
    }, []);

    return (
        <div className="h-full px-4 gradient-bg-5 dropshadow rounded-l pb-10" style={{overflow: 'scroll', scrollbarWidth: 'none'}}>
            <div className="p-5 cursor-pointer"><img src={logo} width={100} alt={'CLC'} onClick={() => switchRoute(navs[0].route)}/></div>
            <ul>
                {
                    navs.map(nav=> {
                        if (nav.allowedFor.some(role => role === parseInt(user.userRole))) {
                            return <li key={nav.id} className={`cursor-pointer hover:bg-slate-300 ease-in duration-300 rounded-md px-4 py-2 m-2 flex justify-left items-center text-sm ${('/' + activeRoute) === nav.route ? "gradient-bg-1" : "bg-slate-100"}`} onClick={() => switchRoute(nav.route)}><div className={nav.icon}></div><div>{nav.label}</div></li>
                        }
                    })
                }
            </ul>
            <div>{user.userRole === userRoles.student && <div className={'text-center text-slate-500'}>Other services are disabled temporarily. <br />Will be activated soon!</div>}</div>
            {!props.hidden ? <div className="md:hidden" style={{height: '100vh', width: '100vw', position: 'fixed', top: 0, zIndex: -3, backdropFilter: 'blur(5px)'}} onMouseEnter={() => props.setHidden(true)}></div> : ''}
        </div>
    );
}

export default Sidebar;
