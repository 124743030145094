import useSWR, { mutate } from "swr";
import { DELETE, GET, POST, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import { App, Button, DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { commonBoolean, messages } from "../../../utils/configConstants";
import { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";
import dayjs from "dayjs";

const ExamSubject = ({ formData }) => {
    const model = '/auth/examSubject';
    const subjectResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/subject' }, fetcher);
    const result = useSWR({ method: GET, url: API_ROUTE + model + '/byExamId/' + formData.id }, fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMarksRecordModalOpen, setIsMarksRecordModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [selectedMarks, setSelectedMarks] = useState({});
    const [form] = Form.useForm();
    const [marksForm] = Form.useForm();
    const { message } = App.useApp();
    const navigate = useNavigate();
    const examMarks = useSWR({ method: GET, url: API_ROUTE + '/auth/examMarks/byExamSubject/' + selectedRecord.id }, fetcher);

    useEffect(() => {
        resetForm();
    }, [])

    const deleteRecord = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + model + '/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }

        result.mutate();
    }

    const deleteMarks = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + "/auth/examMarks" + '/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }

        result.mutate();
        examMarks.mutate();
    }

    const addRecord = () => {
        form.resetFields();
        resetForm();
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsMarksRecordModalOpen(false);
    };

    const onFinish = async (data) => {
        if (selectedRecord.id) {
            // console.log({...data, id: selectedRecord.id})
            await processReq(PUT, API_ROUTE + model, { ...data, id: selectedRecord.id });
            result.mutate();
            message.success('Updated!')
        } else {
            await processReq(POST, API_ROUTE + model, {...data, examId: formData.id});
            result.mutate();
            message.success('Saved!')
        }

        handleCancel();
    }

    const onFinishAddMarks = async (data) => {
        const enrollment = selectedRecord.exam?.course?.enrollments.find(enr => enr.id === data.enrollmentId);

        if (selectedMarks.id) {
            await processReq(PUT, API_ROUTE + "/auth/examMarks", { marks: data.marks, id: selectedMarks.id });
            message.success('Updated!')
        } else {
            await processReq(POST, API_ROUTE + "/auth/examMarks", {...data, examSubjectId: selectedRecord.id, studentId: enrollment.studentId});
            message.success('Saved!')
        }

        marksForm.resetFields();
        setSelectedMarks({})
        examMarks.mutate();
        result.mutate();

        // handleCancel();
    }

    const resetForm = () => {
        setSelectedRecord({})
    }

    const moreAction = (data) => {
        setSelectedRecord(data);
        examMarks.mutate();
        setIsMarksRecordModalOpen(true);
    }

    const editRecord = (record) => {
        let object = {
            ...record,
            examDate: dayjs(record.examDate)
        }

        setSelectedRecord(object);
        form.setFieldsValue(object);
        setIsModalOpen(true);
    }

    const editMarks = (record) => {
        let object = {
            ...record,
            examDate: dayjs(record.examDate)
        }

        setSelectedMarks(object);
        marksForm.setFieldsValue(object);
        examMarks.mutate();
    }

    return (
        <div>
            {
                result.isLoading ? 'Loading' :
                    <div className="p-5 bg-white/60 rounded-xl fade shadow-lg">
                        <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                            <div className="text-xl pb-3">Exam Subjects</div>
                            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Record</Button></div>
                            <div className="flex items-center py-2">
                                <DataTable
                                    columns={[
                                        "id",
                                        "subjectName",
                                        "date"
                                    ]}
                                    data={result.data}
                                    isLoading={result.isLoading}
                                    deleteAction={deleteRecord}
                                    moreAction={moreAction}
                                    editAction={editRecord}
                                />
                            </div>
                        </div>

                        <Modal maskClosable={false} title="Add New Record" open={isModalOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
                            <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                                <Form.Item label='Subject' name={'subjectId'} rules={[{required: commonBoolean.required, message: messages.requiredMessage}]}>
                                    <Select options={subjectResponse.data} placeholder="Select Subject"/>
                                </Form.Item>

                                <Form.Item label='Exam' name={'examDate'} rules={[{required: commonBoolean.required, message: messages.requiredMessage}]}>
                                    <DatePicker />
                                </Form.Item>

                                <Form.Item className="text-right">
                                    <Button htmlType="submit">Save Record</Button>
                                </Form.Item>
                            </Form>
                        </Modal>

                        <Modal maskClosable={false} title="Add New Record" open={isMarksRecordModalOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
                            <Form form={marksForm} className="pt-5" onFinish={onFinishAddMarks} layout="vertical">
                                <Form.Item label='Student' name={'enrollmentId'} rules={[{required: commonBoolean.required, message: messages.requiredMessage}]}>
                                    <Select 
                                        options={selectedRecord.exam?.course?.enrollments} 
                                        showSearch
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                    />
                                </Form.Item>

                                <Form.Item label='Marks' name={'marks'} rules={[{required: commonBoolean.required, message: messages.requiredMessage}]}>
                                    {/* <Input type="number" placeholder="Enter marks"/> */}
                                    <InputNumber min={0} max={100} placeholder="Enter marks" />
                                </Form.Item>

                                <Form.Item className="text-right">
                                    <Button htmlType="submit">Save Record</Button>
                                </Form.Item>
                            </Form>

                            <div className="flex items-center py-2">
                                <DataTable
                                    columns={[
                                        "id",
                                        "marks",
                                        "subjectName"
                                    ]}
                                    data={examMarks.data}
                                    isLoading={result.isLoading}
                                    deleteAction={deleteMarks}
                                    // moreAction={moreAction}
                                    editAction={editMarks}
                                />
                            </div>
                        </Modal>
                    </div>
            }
        </div>
    )
}

export default ExamSubject;