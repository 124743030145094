import useSWR, { mutate } from "swr";
import { DELETE, GET, POST, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import EditInput from "../../../components/EditInput";
import { App, Button, DatePicker, Form, Input, Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { commonBoolean, messages, paymentMethod, paymentStatus, paymentType } from "../../../utils/configConstants";
import { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";
import { usePDF } from "react-to-pdf";
import { Document, Image, PDFViewer, Page, Text, View } from "@react-pdf/renderer";
import header from '../../../assets/img/receipt_header.jpg'
import footer from '../../../assets/img/receipt_footer.jpg'
import dayjs from "dayjs";
import { ReloadOutlined } from "@ant-design/icons";

const Payments = ({ formData }) => {
    const model = '/auth/payment';
    const paymentResponse = useSWR({ method: GET, url: API_ROUTE + "/auth/payment/byEnrollment/" + formData.id }, fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRecieptOpen, setIsRecieptOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [showHeaderFooter, setShowHeaderFooter] = useState(false);
    const [isSubPaymentModalOpen, setIsSubPaymentModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [settlePaymentForm] = Form.useForm();
    const { message } = App.useApp();
    const navigate = useNavigate();
    const { toPDF, targetRef } = usePDF({ filename: 'payment_receipt.pdf' });
    const [paymentCompleted, setPaymentCompleted] = useState();


    useEffect(() => {
        resetForm();
    }, [])

    const deleteRecord = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + model + '/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }

        paymentResponse.mutate();
    }

    const addRecord = () => {
        form.resetFields();
        resetForm();
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsRecieptOpen(false);
        setIsSubPaymentModalOpen(false);
    };

    const onFinish = async (data) => {
        if (selectedRecord.id) {
            // console.log({...data, id: selectedRecord.id})
            await processReq(PUT, API_ROUTE + model, { ...data, id: selectedRecord.id });
            paymentResponse.mutate();
            message.success('Updated!')
        } else {
            await processReq(POST, API_ROUTE + model, { ...data, studentId: formData.studentId, enrollmentId: formData.id, paymentStatus: paymentStatus.toBePaid });
            paymentResponse.mutate();
            message.success('Saved!')
        }

        handleCancel();
    }

    const resetForm = () => {
        setSelectedRecord({});
    }

    const printReceipt = (data) => {
        setSelectedRecord(data);
        setIsRecieptOpen(true);
        // console.log(data, formData);
    }

    const settlePayment = (data) => {
        setSelectedRecord(data);
        setIsSubPaymentModalOpen(true);
        setPaymentCompleted(false);
    }

    const SubPaymentModal = () => {
        const subPaymentResponse = useSWR({ method: GET, url: API_ROUTE + "/auth/subPayment/byPayment/" + selectedRecord.id }, fetcher);
        const subPayment = subPaymentResponse?.data;
        const [receiptData, setReceiptData] = useState({});


        const onSettlePaymentFinish = async (data) => {
            const response = await processReq(POST, API_ROUTE + '/auth/subPayment', {
                ...data,
                studentId: formData.studentId,
                enrollmentId: formData.id,
                paymentId: selectedRecord.id,
                payment: selectedRecord
            });

            subPaymentResponse.mutate();
            message.success('Saved!')
            settlePaymentForm.resetFields();

            if (data.paymentAmount === selectedRecord.toBePaid) {
                setPaymentCompleted(true);
            } else {
                setPaymentCompleted(false);
            }

            setReceiptData(response);
        }

        const deleteSubPayment = async (record) => {
            const response = await processReq(DELETE, API_ROUTE + '/auth/subPayment/del/' + record.id, record);

            if (!response.error) {
                message.success('Record deleted!');
            } else {
                message.error('Deletion failed!');
            }

            paymentResponse.mutate();
            subPaymentResponse.mutate();
        }

        const loadReceipt = async (data) => {
            const response = await processReq(GET, API_ROUTE + '/auth/subPayment/dist/' + data.id);

            setReceiptData(response);
        }

        const isPaymentCompleted = () => {
            return parseInt(selectedRecord.toBePaid) === 0 || parseInt(receiptData?.payment?.toBePaid === 0);
        }

        return (
            <div>
                <div className="flex gap-10">
                    <div className="">
                        {
                            isPaymentCompleted() || paymentCompleted ? <div>Payment Completed</div> :
                                <>
                                    <div>
                                        <div className="">Amount To Be Paid: {selectedRecord.toBePaid}</div>
                                        <div className="">Amount Paid: {selectedRecord.paidAmount}</div>
                                        <div className="">Period: {dayjs(selectedRecord.from).format('YYYY-MM-DD')} - {dayjs(selectedRecord.to).format('YYYY-MM-DD')}</div>
                                    </div>
                                    <div className="">
                                        <Form form={settlePaymentForm} className="pt-5" onFinish={onSettlePaymentFinish} layout="vertical" initialValues={{ toBePaid: formData.monthlyFee, paidAmount: 0, totalAmount: formData.monthlyFee }}>
                                            <Form.Item label='Paid Date' name={'paidDate'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                                <DatePicker />
                                            </Form.Item>
                                            <Form.Item label='Payment Method' name={'paymentMethod'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                                                <Select options={paymentMethod.formData} placeholder="Payment Method" />
                                            </Form.Item>
                                            {/* <Form.Item label='Payment Status' name={'paymentStatus'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                                    <Select options={paymentStatus.formData} placeholder="Payment Status" />
                                </Form.Item> */}
                                            <Form.Item label='Paid Amount' name={'paymentAmount'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                                <Input placeholder="Enter text" type="number" />
                                            </Form.Item>
                                            <Form.Item className="text-right">
                                                <Button htmlType="submit">Settle</Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </>
                        }
                        <div className="flex items-center py-2">
                            <DataTable
                                columns={[
                                    "id",
                                    "paymentAmount",
                                    "method",
                                    "paidOn"
                                ]}
                                data={subPaymentResponse.data}
                                isLoading={subPaymentResponse.isLoading}
                                deleteAction={deleteSubPayment}
                                printAction={loadReceipt}
                            />
                        </div>
                    </div>
                    <div className="">
                        <div className="py-4 text-right">
                            <Button onClick={() => setShowHeaderFooter(prev => !prev)}>Show/Hide Header and Footers</Button>
                        </div>
                        <PDFViewer width="100%" height="600px">
                                <Document ref={targetRef}>
                                    <Page size={"A5"} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <View><Image src={header} style={{ opacity: showHeaderFooter ? 1 : 0 }} /></View>
                                        <View style={{ paddingVertical: 20, paddingHorizontal: 40, flexGrow: 1, fontSize: 12 }}>
                                            <Text style={{ fontSize: 18, paddingBottom: 5 }}>Payment Receipt</Text>
                                            <Text>Recipt ID: {receiptData.id}</Text>
                                            <Text style={{ textAlign: 'right', borderBottom: '1px solid #ccc', paddingBottom: 10 }}>{dayjs().format('MMM DD, YYYY hh:mm A')}</Text>
                                            <Text style={{ textDecoration: 'underline', paddingVertical: 10 }}>Student Details</Text>
                                            <View style={{ fontSize: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <Text style={{ width: '50%' }}>Name: {selectedRecord.student?.fullName}</Text>
                                                <Text style={{ width: '50%' }}>Enrollment ID: {selectedRecord.enrollment?.id}</Text>
                                                <Text style={{ width: '50%' }}>Course: {formData?.courseName}</Text>
                                            </View>
                                            <Text style={{ textDecoration: 'underline', paddingVertical: 10 }}>Payment Details</Text>
                                            <View style={{ fontSize: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <Text style={{ width: '50%' }}>Period: {selectedRecord.period}</Text>
                                                <Text style={{ width: '50%' }}>Payment Type: {selectedRecord.type}</Text>
                                                <Text style={{ width: '50%' }}>Status: {selectedRecord.status}</Text>
                                                <Text style={{ width: '50%' }}>Due: {selectedRecord.due}</Text>
                                                {/* <Text style={{ width: '50%' }}>Paid: {selectedRecord.paidAmount}</Text>
                                                <Text style={{ width: '50%' }}>To be paid: {selectedRecord.toBePaid}</Text> */}
                                            </View>


                                            <Text style={{ textDecoration: 'underline', paddingVertical: 10 }}>Current History</Text>

                                            <View style={{ fontSize: 10, display: 'flex', flexDirection: 'row', width: '100%', fontWeight: 'bold' }}>
                                                <Text style={{ width: '33%' }}>Method</Text>
                                                <Text style={{ width: '33%' }}>Paid Date</Text>
                                                <Text style={{ width: '33%', textAlign: 'right' }}>Amount</Text>
                                            </View>
                                            <View key={receiptData.id} style={{ fontSize: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <Text style={{ width: '33%' }}>{receiptData.method}</Text>
                                                <Text style={{ width: '33%' }}>{receiptData.paidOn}</Text>
                                                <Text style={{ width: '33%', textAlign: 'right' }}>{receiptData.paymentAmount}</Text>
                                            </View>


                                            <Text style={{ textAlign: 'center', paddingVertical: 30 }}>Thank You</Text>
                                            <Text style={{ paddingTop: 20, fontSize: 10 }}>CLC Finance</Text>
                                            <Text style={{ paddingTop: 10,fontSize: 8 }}>This is a system generated document. No need of signatures</Text>
                                        </View>
                                        <View><Image src={footer} style={{ opacity: showHeaderFooter ? 1 : 0 }} /></View>
                                    </Page>
                                </Document>
                            </PDFViewer>
                    </div>
                </div>
            </div>
        )
    }

    const generatePayments = async () => {
        await processReq(GET, API_ROUTE + '/auth/payment/generate/' + formData.id);

        paymentResponse.mutate();
    }

    const reloadData = () => {
        paymentResponse.mutate();
    }

    return (
        <div>
            {
                paymentResponse.isLoading ? 'Loading' :

                    <div className="p-5 bg-white/60 rounded-xl fade shadow-lg">
                        <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                            <div className="text-xl pb-3">Student Payments</div>
                            <div className="text-3xl pb-5 text-right flex justify-end"><Button onClick={reloadData}><ReloadOutlined /></Button><Button onClick={generatePayments}>Generate Payments</Button><Button onClick={addRecord}>Add Record</Button></div>
                            <div className="flex items-center py-2">
                                <DataTable
                                    columns={[
                                        "id",
                                        "toBePaid",
                                        "due",
                                        "type",
                                        "status"
                                    ]}
                                    data={paymentResponse.data}
                                    isLoading={paymentResponse.isLoading}
                                    deleteAction={deleteRecord}
                                    printAction={printReceipt}
                                    moreAction={settlePayment}
                                />
                            </div>
                        </div>

                        <Modal title="Print" open={isRecieptOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }}>
                            <div className="py-4 text-right">
                                <Button onClick={() => setShowHeaderFooter(prev => !prev)}>Show/Hide Header and Footers</Button>
                            </div>
                            <PDFViewer width="100%" height="600px">
                                <Document ref={targetRef}>
                                    <Page size={"A5"} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <View><Image src={header} style={{ opacity: showHeaderFooter ? 1 : 0 }} /></View>
                                        <View style={{ paddingVertical: 20, paddingHorizontal: 40, flexGrow: 1, fontSize: 12 }}>
                                            <Text style={{ fontSize: 18, paddingBottom: 5 }}>Payment Receipt</Text>
                                            <Text>Recipt ID: {selectedRecord.id}</Text>
                                            <Text style={{ textAlign: 'right', borderBottom: '1px solid #ccc', paddingBottom: 10 }}>{dayjs().format('MMM DD, YYYY hh:mm A')}</Text>
                                            <Text style={{ textDecoration: 'underline', paddingVertical: 10 }}>Student Details</Text>
                                            <View style={{ fontSize: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <Text style={{ width: '50%' }}>Name: {selectedRecord.student?.fullName}</Text>
                                                <Text style={{ width: '50%' }}>Enrollment ID: {selectedRecord.enrollment?.id}</Text>
                                                <Text style={{ width: '50%' }}>Course: {formData?.courseName}</Text>
                                            </View>
                                            <Text style={{ textDecoration: 'underline', paddingVertical: 10 }}>Payment Details</Text>
                                            <View style={{ fontSize: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <Text style={{ width: '50%' }}>Period: {selectedRecord.period}</Text>
                                                <Text style={{ width: '50%' }}>Payment Type: {selectedRecord.type}</Text>
                                                <Text style={{ width: '50%' }}>Status: {selectedRecord.status}</Text>
                                                <Text style={{ width: '50%' }}>Due: {selectedRecord.due}</Text>
                                                <Text style={{ width: '50%' }}>Paid: {selectedRecord.paidAmount}</Text>
                                                <Text style={{ width: '50%' }}>To be paid: {selectedRecord.toBePaid}</Text>
                                            </View>


                                            {
                                                selectedRecord?.subPayments?.length > 0 ?
                                                    <>
                                                        <Text style={{ textDecoration: 'underline', paddingVertical: 10 }}>Payment History</Text>

                                                        <View style={{ fontSize: 10, display: 'flex', flexDirection: 'row', width: '100%', fontWeight: 'bold' }}>
                                                            <Text style={{ width: '33%' }}>Method</Text>
                                                            <Text style={{ width: '33%' }}>Paid Date</Text>
                                                            <Text style={{ width: '33%', textAlign: 'right' }}>Amount</Text>
                                                        </View>
                                                        {
                                                            selectedRecord?.subPayments?.map(receiptData => {
                                                                return (
                                                                    <View key={receiptData.id} style={{ fontSize: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                        <Text style={{ width: '33%' }}>{receiptData.method}</Text>
                                                                        <Text style={{ width: '33%' }}>{receiptData.paidOn}</Text>
                                                                        <Text style={{ width: '33%', textAlign: 'right' }}>{receiptData.paymentAmount}</Text>
                                                                    </View>
                                                                )
                                                            })
                                                        }</>
                                                    : <></>
                                            }


                                            <Text style={{ textAlign: 'center', paddingVertical: 30 }}>Thank You</Text>
                                            <Text style={{ paddingTop: 20, fontSize: 10 }}>CLC Finance</Text>
                                            <Text style={{ paddingTop: 10,fontSize: 8 }}>This is a system generated document. No need of signatures</Text>
                                        </View>
                                        <View><Image src={footer} style={{ opacity: showHeaderFooter ? 1 : 0 }} /></View>
                                    </Page>
                                </Document>
                            </PDFViewer>
                        </Modal>

                        <Modal maskClosable={false} title="Add New Record" open={isModalOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
                            <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical" initialValues={{ toBePaid: formData.monthlyFee, paidAmount: 0, totalAmount: formData.monthlyFee }}>
                                {/* <Form.Item label='Payment Status' name={'paymentStatus'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                                    <Select options={paymentStatus.formData} placeholder="Payment Status" />
                                </Form.Item> */}
                                <Form.Item label='To Be Paid' name={'toBePaid'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                    <Input placeholder="Enter text" type="number" />
                                </Form.Item>
                                <Form.Item label='Paid Amount' name={'paidAmount'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                    <Input placeholder="Enter text" type="number" />
                                </Form.Item>
                                <Form.Item label='Total Amount' name={'totalAmount'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                    <Input placeholder="Enter text" type="number" />
                                </Form.Item>
                                <Form.Item label='Payment Type' name={'paymentType'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                                    <Select options={paymentType.formData} placeholder="Payment Type" />
                                </Form.Item>
                                <div className="flex gap-5">
                                    <Form.Item label='From' name={'from'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                        <DatePicker />
                                    </Form.Item>
                                    <Form.Item label='To' name={'to'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                        <DatePicker />
                                    </Form.Item>
                                    {/* <Form.Item label='Paid Date' name={'paidDate'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                        <DatePicker />
                                    </Form.Item> */}
                                </div>
                                <Form.Item label='Description' name={'description'}>
                                    <Input placeholder="Enter text" />
                                </Form.Item>

                                <Form.Item className="text-right">
                                    <Button htmlType="submit">Save Record</Button>
                                </Form.Item>
                            </Form>
                        </Modal>

                        <Modal maskClosable={false} width={'100vw'} title="Settle Payment" open={isSubPaymentModalOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
                            <SubPaymentModal />
                        </Modal>
                    </div>
            }
        </div>
    )
}

export default Payments;
