import useSWR from "swr";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { fetcher } from "../helpers/processRequest";
import { GET } from "../utils/requestActionConstants";
import { API_ROUTE } from "../utils/commonConstants";
import { useNavigate } from "react-router-dom";
import { CloseCircleFilled } from "@ant-design/icons";
import TimeTable from "../pages/timetable/TimeTable";

const NoticeBoardView = () => {
    const result = useSWR({ method: GET, url: API_ROUTE + '/auth/notice' }, fetcher);
    const [noticeString, setNoticeString] = useState('');
    const navigate = useNavigate();
    const [showVideo, setShowVideo] = useState(false);
    const [showScreenSaver, setShowScreenSaver] = useState(false);

    useEffect(() => {
        handleNoticeUpdate();
    }, [result.data])

    useEffect(() => {
        // const timeoutId = setTimeout(() => {
        //     setShowVideo((prev) => !prev);
        // }, 2000); // 2 seconds in milliseconds

        // return () => clearTimeout(timeoutId); // Cleanup function to avoid memory leaks
    }, []);

    const handleNoticeUpdate = () => {
        const noticesToShow = [];

        result.data?.filter((notice) => {
            if (notice.isVisible) {
                noticesToShow.push(notice.content);
            }
        })

        setNoticeString(noticesToShow.reverse().join(' - '));
    }

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-blue-300 fixed top-0 left-0 w-screen h-screen z-50">
            <div className="text-2xl pb-5 font-bold">Notice Board</div>
            <div className="text-2xl pb-5 font-bold rounded-2xl bg-red-600 text-white p-4">NOW: 05:30 - 07:30 ICT Thanish | 08:30 - 10:30 Accounting Mr. Kelum</div>
            <div className=" bg-yellow-400 rounded-2xl py-8 text-9xl overflow-hidden px-5"><Marquee speed={100} style={{overflow: 'hidden'}}>{noticeString}</Marquee></div>
            

            {
                showVideo ?
                    <div onClick={() => setShowVideo(false)} className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white fixed top-0 left-0 w-screen h-screen z-50">
                        Another Sample
                    </div>
                    
                    : <>
                    </>
            }

                  <div className={'screen-saver'}>
                    <video autoPlay loop muted poster="https://assets.codepen.io/6093409/river.jpg" width={'100%'}>
                      <source src="https://assets.codepen.io/6093409/river.mp4" type="video/mp4" />
                    </video>
                  </div>
        </div>
    );
}

export default NoticeBoardView;