import { Form, Upload } from "antd";
import { API_ROUTE } from "../utils/commonConstants";
import { headerConfig, processReq } from "../helpers/processRequest";
import { POST } from "../utils/requestActionConstants";

const UploadFile = ({onReload, deleteImage}) => {
    const [form] = Form.useForm();

    const uploadImage = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        
        const fmData = new FormData();
        let config = headerConfig();

        config.headers = {...config.headers, "content-type": "multipart/form-data"};
        config.onUploadProgress = (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 },file);
        }

        let fileLabel = prompt("Please enter a file name which will be easy to search", "");

        fmData.append("file", file);
        fmData.append("fileLabel", fileLabel)
        
        try {
            const response = await processReq(POST, API_ROUTE + '/auth/upload', fmData, config);
    
            if(response.error) {
                onError(response)
            } else {
                onReload();
                onSuccess(response);
            }
        } catch (error) {
            onError(error);
        }
    }

    return (
        <div>
            <Form form={form} className="pt-5" layout="vertical">
                <Form.Item label="Upload" valuePropName="fileList">
                    <Upload listType="picture-card" onRemove={deleteImage} customRequest={uploadImage} accept="/*">
                        <div className="text-4xl">+</div>
                    </Upload>
                </Form.Item>
            </Form>
        </div>
    )
}

export default UploadFile;