import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Skeleton, message } from "antd"
import { API_ROUTE } from "../utils/commonConstants";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { isEmptyString } from "../utils/validations";

const ImageGallery = (props) => {
    const {imagesData, deleteImage} = props;
    const [filteredFiles, setFilteredFiles] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [showingAll, setShowingAll] = useState(false);

    useEffect(() => {
        filterFiles();
    }, [imagesData.data, searchKey]);

    const copyToClipboard = (id) => {
        let url = `${API_ROUTE}/upload/${id}`;
        navigator.clipboard.writeText(url)
        message.success(url + ' has been copied to clipboard');
    }

    const handleFileSearch = (event) => {
        setSearchKey(event.target.value);
    }

    const filterFiles = () => {
        if (isEmptyString(searchKey)) {
            setFilteredFiles(imagesData.data);
            // console.log('Not Filtered');
        } else {
            let filtered = imagesData.data.filter(file => file.fileLabel ? file.fileLabel.includes(searchKey) : '');

            setFilteredFiles(filtered);
            // console.log('Filtered');
        }
    }

    const showAll = () => {
        setShowingAll((old) => !old);
    }

    return (
        <>
            <div className="flex">
                <Search onChange={handleFileSearch} placeholder="Search Files Here..."/>
                <Button onClick={showAll}>{showingAll ? 'Show Less' : 'Show All'}</Button>
            </div>
            <Row gutter={[16, 16]} style={{transition: 1, maxHeight: 500, overflow: 'scroll'}}>
                {
                    !imagesData.isLoading ? filteredFiles && filteredFiles.slice(0, showingAll ? filteredFiles.length : 6).map(file => {
                        return (
                            <Col span={6} key={file.id} style={{border: '1px solid #F6FAFF', borderRadius: 10, margin: 10, textAlign: 'center'}}>
                                <div className="flex" >
                                    <img title="click to copy link" loading="lazy" src={`${API_ROUTE}/upload/${file.id}`} className="cursor-pointer" alt={file.file_name} onClick={()=>copyToClipboard(file.id)} />
                                    <div className="flex mx-2" style={{justifyContent: 'space-around', flexDirection: 'column'}}>
                                            {deleteImage ?
                                            <Popconfirm
                                                title="Delete the image"
                                                description="Are you sure to delete this record?"
                                                onConfirm={() => deleteImage({response: file})}
                                                okButtonProps={{danger: true}}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <DeleteOutlined />
                                            </Popconfirm>
                                            : ''}
                                            <CopyOutlined onClick={()=>copyToClipboard(file.id)}/>
                                    </div>
                                </div>
                                <div>{file.fileLabel ? file.fileLabel : 'N/A'}</div>
                            </Col>
                        )
                    }) : ''
                }

                {
                    imagesData.isLoading ?
                    <div>
                        <Skeleton.Image active={true} style={{borderRadius: 10, margin: 5}}/>
                        <Skeleton.Image active={true} style={{borderRadius: 10, margin: 5}}/>
                        <Skeleton.Image active={true} style={{borderRadius: 10, margin: 5}}/>
                        <Skeleton.Image active={true} style={{borderRadius: 10, margin: 5}}/>
                        <Skeleton.Image active={true} style={{borderRadius: 10, margin: 5}}/>
                        <Skeleton.Image active={true} style={{borderRadius: 10, margin: 5}}/>
                    </div>
                    : ''
                }
            </Row>
        </>
    )
}

export default ImageGallery;
