import useSWR, { mutate } from "swr";
import { GET, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import EditInput from "../../../components/EditInput";

const LecturerDetails = ({formData}) => {
    const subjectResponse = useSWR({method: GET, url: API_ROUTE + '/auth/subject'} , fetcher);
    const lecturerResponse = useSWR({method: GET, url: API_ROUTE + '/auth/lecturer/dist/'+formData.lecturerId} , fetcher);
    const lecturer = lecturerResponse.data;

    let onSuccess = (value, field) => {
        return new Promise(async (resolve, reject) => {
            let error = false;
            let reqObject = lecturer;

            reqObject[field] = value;

            let result = await processReq(PUT, API_ROUTE + '/auth/lecturer', reqObject);

            error = !(result && result.length > 0);

            if (error) {
                reject('Failed')
            } else {
                resolve('success');
                lecturerResponse.mutate();
            }
        })
    }

    return (
        <div>
            {
                lecturerResponse.isLoading ? 'Loading' : 

                <div className="p-5 bg-white/60 rounded-xl fade shadow-lg">
                        <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                            <div className="text-xl pb-3">Lecturer Details</div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">First Name</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={lecturer.firstName} 
                                        onSuccess={onSuccess} 
                                        field={'firstName'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Last Name</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={lecturer.lastName} 
                                        onSuccess={onSuccess} 
                                        field={'lastName'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">NIC</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={lecturer.nic} 
                                        onSuccess={onSuccess} 
                                        field={'mic'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Mobile</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={lecturer.mobile} 
                                        onSuccess={onSuccess} 
                                        field={'mobile'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">WhatsApp Mobile</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={lecturer.whatsAppMobile} 
                                        onSuccess={onSuccess} 
                                        field={'whatsAppMobile'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Per Hour Rate</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={lecturer.perHourRate} 
                                        onSuccess={onSuccess} 
                                        field={'perHourRate'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Per Day Rate</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={lecturer.perDayRate} 
                                        onSuccess={onSuccess} 
                                        field={'perDayRate'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Per Month Rate</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={lecturer.perMonthRate} 
                                        onSuccess={onSuccess} 
                                        field={'perMonthRate'}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            }
        </div>
    )
}

export default LecturerDetails;