import useSWR, { mutate } from "swr";
import { GET, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import EditInput from "../../../components/EditInput";
import { district } from "../../../utils/configConstants";

const ManageStudent = ({formData}) => {
    const studentRequest = useSWR({method: GET, url: API_ROUTE + '/auth/student/dist/'+formData.studentId} , fetcher);
    const student = studentRequest.data;

    let onSuccess = (value, field) => {
        return new Promise(async (resolve, reject) => {
            let error = false;
            let reqObject = student;

            reqObject[field] = value;

            let result = await processReq(PUT, API_ROUTE + '/auth/student', reqObject);

            error = !(result && result.length > 0);

            if (error) {
                reject('Failed')
            } else {
                resolve('success');
                studentRequest.mutate();
            }
        })
    }

    return (
        <div>
            {
                studentRequest.isLoading ? 'Loading' : 

                <div className="p-5 bg-white/60 rounded-xl fade shadow-lg">
                        <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                            <div className="text-xl pb-3">Student Details</div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">First Name</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={student.firstName} 
                                        onSuccess={onSuccess} 
                                        field={'firstName'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Last Name</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={student.lastName} 
                                        onSuccess={onSuccess} 
                                        field={'lastName'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Email</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={student.email} 
                                        onSuccess={onSuccess} 
                                        field={'email'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Permanant Address</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={student.permAddress} 
                                        onSuccess={onSuccess} 
                                        field={'permAddress'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Postal Address</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={student.postAddress} 
                                        onSuccess={onSuccess} 
                                        field={'postAddress'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3" >District</div> : <div className="pl-2">
                                    <EditInput
                                        type={'dropdown'}
                                        masterData={student.district}
                                        onSuccess={onSuccess}
                                        dropdownData={district.formData}
                                        field={'district'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Date of Birth</div> : <div className="pl-2">
                                    <EditInput 
                                        masterData={student.dob} 
                                        onSuccess={onSuccess} 
                                        field={'dob'}
                                        type={'date'}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            }
        </div>
    )
}

export default ManageStudent;